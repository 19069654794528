import logger from '~/services/logger';
import { getZappClientUUID } from '../../utils/analytics.tsx';
import type { EventName } from '../../utils/analytics.tsx';

declare global {
  interface Window {
    gtag: (
      option: string,
      gaTrackingId: string,
      options: Record<string, unknown>
    ) => void;
  }
}

export function track(eventName: EventName, properties: any) {
  if (!isGaEnabled()) return;

  const uuid = getZappClientUUID();

  window.gtag('event', eventName, {
    uuid,
    ...properties,
  });
}

export function isGaEnabled(): boolean {
  if (!window.gtag) {
    logger.info(
      'window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.'
    );
    return false;
  }
  return true;
}
